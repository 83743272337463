<template>
  <router-view />
</template>

<style lang="scss">
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins.ttf");
}

@font-face {
  font-family: "Ubuntu";
  src: url("./assets/fonts/Ubuntu.ttf");
}

@import "assets/scss/custom-bootstrap.scss";
</style>
