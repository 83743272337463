export const routes = [
  {
    path: "/",
    name: "Accueil",
    meta: {
      title: "ST ARIMA",
    },
    component: () => import("../views/visiteur/Accueil.vue"),
  },
  {
    path: "/services",
    name: "Service",
    meta: {
      title: "Services",
    },
    component: () => import("../views/visiteur/Service.vue"),
  },
  {
    path: "/formations",
    name: "Formation",
    meta: {
      title: "Formations",
    },
    component: () => import("../views/visiteur/Formation.vue"),
  },
  {
    path: "/a-propos",
    name: "APropos",
    meta: {
      title: "A propos",
    },
    component: () => import("../views/visiteur/AboutUs.vue"),
  },
  {
    path: "/se-connecter",
    name: "LoginArima",
    meta: {
      title: "Se connecter",
    },
    component: () => import("../views/visiteur/Login.vue"),
  },
  {
    path: "/se-connecter-admin",
    name: "LoginArimaAdmin",
    meta: {
      title: "Se connecter",
    },
    component: () => import("../views/admin/LoginAdmin.vue"),
  },
  {
    path: "/admin",
    component: () => import("../layout/LayoutAdmin.vue"),
    meta: {},
    children: [
      {
        path: "dashboard",
        name: "DashboardAdmin",

        component: () => import("../views/admin/Dashboard.vue"),
        meta: {
          title: "Tableau de bord ST ARIMA",
        },
      },
      {
        path: "service",
        name: "ServiceAdmin",

        component: () => import("../views/admin/Service.vue"),
        meta: {
          title: "Services & Activités",
        },
      },
      {
        path: "formation",
        name: "FormationAdmin",

        component: () => import("../views/admin/Formation.vue"),
        meta: {
          title: "Formations",
        },
      },
      {
        path: "client",
        name: "ClientAdmin",

        component: () => import("../views/admin/Client.vue"),
        meta: {
          title: "Clients",
        },
      },
      {
        path: "mail",
        name: "MailAdmin",

        component: () => import("../views/admin/Mail.vue"),
        meta: {
          title: "Mails",
        },
      },
      {
        path: "article",
        name: "ArticleAdmin",

        component: () => import("../views/admin/Article.vue"),
        meta: {
          title: "Articles",
        },
      },
      {
        path: "user",
        name: "UserAdmin",

        component: () => import("../views/admin/User.vue"),
        meta: {
          title: "Utilisateurs",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)",
    name: "NotFound",
    component: () => import("../views/visiteur/NotFound.vue"),
    meta: {
      title: "Page introuvable",
    },
  },
];
